import "@mantine/carousel/styles.layer.css";
import "@mantine/core/styles.layer.css";
import "@mantine/dropzone/styles.layer.css";
import "@mantine/notifications/styles.layer.css";
import "@mantine/nprogress/styles.layer.css";
import "@evotix/napier-ui-common-components/styles.css";
import "@evotix/napier-ui-common-icons/styles.css";
import "./styles/application.scss";

import "@total-typescript/ts-reset";
import "./translations";
import "./lib/analytics";

import React, { lazy } from "react";
import { NapierBridgeCommon } from "@evotix/napier-ui-common-native-bridge";
import { createRoot } from "react-dom/client";
import wretch from "wretch";

import { GlobalConfigProvider } from "./components/GlobalConfigProvider";
import { dynamicConfigSchema, globalConfig, globalConfigUrl } from "./lib/config";
import { applicationContext } from "./lib/context/application-context";
import { injectGainsightTag } from "./lib/external/gainsight/utilities";
import { initialiseNativeAppAuthentication } from "./lib/offline/authentication";

async function enableMocking() {
	/**
	 * We don't want to enable mocking in production
	 */
	if (import.meta.env.PROD) return Promise.resolve();

	/**
	 * We don't want to enable the mocking unless were on the hosted url for this application
	 */
	if (globalConfig.get().endpointUrl !== "https://localhost:5174") return Promise.resolve();

	/**
	 * We lazy load the mock service worker here so that we don't attempt to load it in production
	 */
	const { worker } = await import("./mocks/browser");

	/**
	 * `worker.start()` returns a promise that resolves once the service worker is up and ready to intercept requests.
	 */
	return worker.start({ onUnhandledRequest: "bypass" });
}

/**
 * We lazy load the app here, so that we don't attemp to load/run any application code that this imports
 */
const Application = lazy(() => import("./App").then((module) => ({ default: module.App })));

wretch(globalConfigUrl)
	.get()
	.json(dynamicConfigSchema.parse)
	.then((config) => {
		globalConfig.set(config);

		injectGainsightTag(config.keys.gainsight);
	})
	.then(async () => {
		await enableMocking();
		await import("./lib/external/logging/initLog");

		const bridgeInitialisationResponse = await NapierBridgeCommon.initialise();
		await initialiseNativeAppAuthentication(bridgeInitialisationResponse);
		applicationContext.platform = bridgeInitialisationResponse.platform;

		createRoot(document.getElementById("root") as HTMLElement).render(
			<React.StrictMode>
				<GlobalConfigProvider value={globalConfig.get()}>
					<Application />
				</GlobalConfigProvider>
			</React.StrictMode>,
		);
	});
